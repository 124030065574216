import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {from, Observable} from 'rxjs';
import {LanguageModel} from '../models/language.model';
import {HttpClient} from '@angular/common/http';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends EntityCollectionServiceBase<LanguageModel> {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    protected serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('language', serviceElementsFactory);
  }

  find(languageId: any): Observable<LanguageModel> {
    return this.http.get<LanguageModel>(`${environment.apiDomain}/${languageId}`);
  }
}
