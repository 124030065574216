import {Injectable} from '@angular/core';
import {LanguageKeyModel} from '../models/language-key.model';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from '@ngrx/data';

@Injectable({
  providedIn: 'root'
})
export class LanguageKeyService extends EntityCollectionServiceBase<LanguageKeyModel> {

  constructor(
    protected serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    super('languageKey', serviceElementsFactory);
  }
}
